body {
  margin: 0;
  padding: 0;
  overflow: hidden;
  background-color: #fff;
}

.box {
  position: fixed;
  text-align: center;
  margin: 0;
  padding: 0;
  height: 100%;
}

.iframe {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}