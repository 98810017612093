.item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 10px;
  border-bottom: 1px solid #ebebeb;
}

.title {
  color: rgb(56, 56, 56);
}

.btn {
  width: 50px;
  height: 25px;
  line-height: 25px;
  padding: 2px 5px;
  text-align: center;
  border-radius: 5px;
  font-size: 14px;
  color: #fff;
  background-color: #f88e24;
  cursor: pointer;
}