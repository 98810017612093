.flex_center_box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.float_customer {
  width: 140px;
  height: 140px;
  margin: 10px 0;
}

.float_customer_label {
  display: flex;
  color: rgb(88, 88, 88);
}

.float_customer_label:nth-child(3) {
  margin-bottom: 7px;
}

.float_customer_label .phone {
  color: #f88e24;
}

.customer_palyer {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
}

.customer_label {
  width: 90px;
  text-align: right;
}

.float_ios_customer {
  text-align: center;
  margin: 15px 15px 0;
}

.float_ios_customer_title {
  font-size: 22px;
  font-weight: bold;
  margin: 15px auto;
}

.float_ios_customer_content {
  text-align: left;
  font-size: 15px;
  color: rgb(88, 88, 88);
}

.float_ios_concat {
  margin-top: 10px;
}

.float_ios_customer_btn {
  width: 130px;
  height: 35px;
  line-height: 35px;
  margin: 20px auto 0;
  color: #fff;
  background-color: #f88e24;
  border-radius: 5px;
  cursor: pointer;
}