
.floatmod {
  width: 70px;
  height: 58px;
  /* position: fixed; */
  cursor: pointer;
}

.floaticon {
  width: 50px;
  height: 50px;
  padding: 4px 10px;
  position: absolute;
  left: 0;
  z-index: 3;
}

.float_content {
  display: flex;
  align-items: center;
  justify-content: space-around;
  position: absolute;
  top: 0;
  height: 58px;
  border-radius: 29px;
  box-sizing: border-box;
  /* background: rgba(255, 255, 255, .8); */
  background: rgb(183, 183, 183);
  z-index: 2;
  opacity: .9;
}

.dialog {
  background-color: rgba(255, 255, 255, 0.2);
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 10;
}

.dialog .box {
  width: 100%;
  max-width: 370px;
  min-height: 300px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  box-shadow: 0 5px 20px 0px rgba(0, 0, 0, .2);
  border-radius: 10px;
  overflow: hidden;
}

.dialog .box .title {
  width: 100%;
  height: 50px;
  line-height: 50px;
  text-align: center;
  color: #fff;
  background-color: #f88e24;
}

.box .dialog_close {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 22px;
  height: 22px;
  font-size: 15px;
  color: #fff;
  cursor: pointer;
  border-radius: 50%;
  border: 1px solid #fff;
  position: fixed;
  top: 12px;
  right: 15px;
}

.dialog .box .content {
  text-align: center;
  padding: 10px;
}

.float_dialog .box {
  min-height: 320px !important;
}

.float_dialog .float_nav_content {
  width: 100%;
  max-height:  250px;
  overflow: auto;
}

.flex_center_box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.float_content .float_item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  z-index: 9999;
}

.img_item {
  width: 27px;
  margin-bottom: 3px;
  z-index: 9999;
}

.img_label {
  white-space: nowrap;
}

.float_content .float_item span {
  font-size: 12px;
}

.float_nav_content {
  padding-bottom: 20px;
}

.float_gift_content {
  font-size: 15px;
  color: #999;
  margin-top: 90px;
  text-align: center;
}

.float_row {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  font-size: 14px;
  padding: 10px 20px;
  cursor: pointer;
  border-bottom: 1px solid #ececec;
}

.float_left {
  display: flex;
  align-items: center;
}

.float_left img {
  width: 30px;
  margin-right: 5px;
}

.float_left span {
  margin-right: 3px;
}

.float_right {
  display: flex;
  align-items: center;
  color: #999;
}

.gift_row {
  cursor: default !important;
}

.gift_row .float_left{
  flex-direction: column;
  font-size: 13px;
  color: #999;
  align-items: flex-start !important;
}

.gift_row .float_left span {
  margin-bottom: 5px;
}

.green {
  color: rgb(15, 185, 15);
}

.red {
  color: rgb(179, 5, 5);
}

.gift_value {
  margin-left: 5px;
}

.float_right .btn {
  width: 60px;
  height: 33px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  color: #fff;
  background-color: #f88e24;
}

.float_right .btn.active {
  background-color: rgb(194, 194, 194) !important;
  cursor: default !important;
}

.icon_arrow {
  display: block;
  content: '';
  width: 8px;
  height: 8px;
  margin-left: 5px;
  border: 3px solid #ccc;
  border-width: 2px 2px 0 0;
  transform: rotate(45deg);
}

.nav {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  height: 40px;
  font-size: 15px;
  color: #999;
  background-color: #f4f4f4;
  position: relative;
}

.nav_item {
  width: 60px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-bottom: 1px solid transparent;
}

.nav .nav_item.active {
  color: #f88e24 !important;
  border-bottom: 1px solid #f88e24;
}

.nav .nav_item.active .icon_arrow {
  display: block;
  content: '';
  width: 8px;
  height: 8px;
  margin-left: 5px;
  border: 3px solid #f88e24;
  border-width: 2px 2px 0 0;
  transform: rotate(45deg);
}

.label {
  margin-right: 10px;
}

.user_dialog .box .content {
  padding: 10px 25px !important;
}

.user_row {
  width: 100%;
  margin: 10px 0;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid #ececec;
}

.user_row .close {
  background: #f88e24;
  color: #fff;
  border-radius: 50%;
  width: 26px;
  height: 23px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.user_row span {
  white-space: nowrap;
  font-size: 13px;
  cursor: pointer;
}

.user_btn_row {
  width: 100%;
  margin: 10px 0;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
}

.user_btn_row .btn {
  width: 100%;
  height: 40px;
  color: #fff;
  font-size: 16px;
  border-radius: 5px;
  margin-top: 10px;
  background-color: #f88e24;
  border: none;
  cursor: pointer;
}

.user_btn_row span {
  margin-top: 5px;
  font-size: 15px;
  color: #f88e24;
  cursor: pointer;
}

.input {
  width: 100%;
  height: 40px;
  border: none;
  outline: none;
}

.dialog_btn {
  cursor: pointer;
  color: #f88e24;
  z-index: 99999;
  padding: 5px 8px;
  user-select: none;
}