.box {
  max-height: 250px;
  font-size: 14px;
  color: #999;
}

.title {
  text-align: center;
  font-size: 16px !important;
  margin-top: 10px;
}

.item {
  display: flex;
  justify-content: space-between;
  padding: 20px 10px;
  border-bottom: 1px solid #ececec;
}

.left {
  width: 78%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.right {
  width: 20%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.row {
  margin-bottom: 5px;
}

.label {
  margin-right: 5px;
}

.btn {
  width: 100%;
  height: 35px;
  line-height: 35px;
  color: #fff;
  font-size: 15px;
  border-radius: 5px;
  text-align: center;
  background-color: #f88e24;
  border: none;
  cursor: pointer;
}

.none {
  text-align: center;
  margin-top: 90px;
  font-size: 15px;
}

.btn_none {
  background-color: #999 !important;
  cursor: auto !important;
}

/* 提现 */
.withdraw_box {
  height: 100%;
  min-height: 250px;
  padding: 10px 20px;
  box-sizing: border-box;
  overflow: auto;
  color: rgb(255, 198, 150) !important;
  background-color: rgb(209, 58, 55);
}

.title_box {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.withdraw_box .title {
  margin-top: 0 !important;
  font-size: 20px !important;
}

.sub_title {
  text-align: center;
  margin-top: 5px;
}

.withdraw_box .btn {
  height: 35px !important;
  line-height: 35px !important;
}

.prompt {
  margin-top: 10px;
  border-radius: 10px;
  padding: 8px 10px;
  box-sizing: border-box;
  background-color: rgb(185, 46, 42);
}

.withdraw_list_box {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.withdraw_btn {
  width: 100px !important;
  margin: 10px 0;
}

.warn {
  color: rgb(240, 133, 75);
}