.dialog {
  background-color: rgba(255, 255, 255, 0.2);
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 10;
}

.dialog .box {
  width: 100%;
  max-width: 370px;
  min-height: 260px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  box-shadow: 0 5px 20px 0px rgba(0, 0, 0, .2);
  border-radius: 10px;
  overflow: hidden;
}

.dialog .box .title {
  width: 100%;
  height: 50px;
  line-height: 50px;
  text-align: center;
  color: #fff;
  background-color: #f88e24;
}

.box .dialog_close {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 22px;
  height: 22px;
  font-size: 15px;
  color: #fff;
  cursor: pointer;
  border-radius: 50%;
  border: 1px solid #fff;
  position: fixed;
  top: 12px;
  right: 15px;
}

.dialog .box .content {
  text-align: center;
  padding: 10px;
}

.verfity_time {
  cursor: auto;
}

.user_row {
  width: 100%;
  margin: 10px 0;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid #ececec;
}

.user_row .close {
  background: #f88e24;
  color: #fff;
  border-radius: 50%;
  width: 26px;
  height: 23px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.user_row span {
  white-space: nowrap;
  font-size: 13px;
  cursor: pointer;
}

.input {
  width: 100%;
  height: 40px;
  border: none;
  outline: none;
}

.user_btn_row {
  width: 100%;
  margin: 10px 0;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
}

.user_btn_row .btn {
  width: 100%;
  height: 40px;
  color: #fff;
  font-size: 16px;
  border-radius: 5px;
  margin-top: 10px;
  background-color: #f88e24;
  border: none;
  cursor: pointer;
}

.user_btn_row span {
  margin-top: 5px;
  font-size: 15px;
  color: #f88e24;
  cursor: pointer;
}
