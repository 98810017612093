.body {
  width: 100%;
  height: 100vh;
  /* background-color: #ececec; */
  background-color: #999;
  -webkit-tap-highlight-color:rgba(0,0,0,0);
  /* -webkit-tap-highlight-color:#ececec; */
}

a:focus, input:focus, p:focus, div:focus{
  -webkit-tap-highlight-color:rgba(0,0,0,0);
  -webkit-user-modify:read-write-plaintext-only; 
}

.box {
  width: 100%;
  max-width: 370px;
  min-height: 270px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  border-radius: 15px;
  box-sizing: border-box;
  overflow: hidden;
}

.title {
  width: 100%;
  height: 50px;
  line-height: 50px;
  text-align: center;
  margin-bottom: 5px;
  color: #fff;
  background-color: #f88e24;
}

.content {
  padding: 0 30px;
}

.row {
  width: 100%;
  margin: 10px 0;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid #ececec;
  position: relative;
}

.icon_arrow {
  display: block;
  content: '';
  width: 14px;
  height: 14px;
  margin-left: 10px;
  margin-top: 5px;
  cursor: pointer;
  border: 3px solid #f88e24;
  border-width: 0 3px 3px 0;
  transform: rotate(225deg);
  transition: transform .2s linear;
}

.icon_arrow.active {
  transform: rotate(45deg) !important;
  transition: transform .2s linear;
  margin-top: -5px !important;
}

.refresh_svg {
  width: 25px;
  height: 25px;
  text-align: center;
  line-height: 25px;
  color: #f88e24;
  cursor: pointer;
  margin-left: 10px;
}

.username_right {
  width: 60px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.username_box {
  width: 100%;
  max-height: 150px;
  position: absolute;
  top: 40px;
  font-size: 14px;
  cursor: pointer;
  overflow-y: auto;
  color: rgb(46, 46, 46);
  background-color: #fff;
  border: 1px solid #ececec;
  z-index: 999;
}

.username_row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 10px;
}

.username_close {
  background: #f88e24;
  color: #fff;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.password_row {
  margin: 10px 0 15px !important;
}

.forget {
  white-space:nowrap;
  margin-left: 5px;
  color: #f88e24;
  cursor: pointer;
}

.time {
  font-size: 15px;
  color: #f88e24;
  white-space: nowrap;
}

.input {
  width: 100%;
  height: 40px;
  border: none;
  outline: none;
}

.icon {
  width: 35px;
  height: 35px;
  cursor: pointer;
}

.send_verify {
  white-space: nowrap;
  font-size: 15px;
  color: #f88e24;
  margin-left: 5px;
  cursor: pointer;
}

.agreement {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 15px 0;
  font-size: 15px;
  color: #999;
}

.agreement span {
  white-space: nowrap;
}

.agreement > input[type="checkbox"] {
  width: 20px;
  height: 20px;
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  line-height: 18px;
  margin-right: 10px;
  position: relative;
}

.agreement > input[type="checkbox"]::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  background: #fff;
  width: 100%;
  height: 100%;
  border: 1px solid #f88e24;
  border-radius: 4px;
}

.agreement > input[type="checkbox"]:checked::before {
  content: "\2713";
  background-color: #fff;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  border: 1px solid #f88e24;
  border-radius:4px;
  color: #f88e24;
  font-size: 20px;
  font-weight: bold;
}

.privacy_agreement {
  color: #f88e24;
  cursor: pointer;
}

.btn {
  width: 100%;
  height: 40px;
  color: #fff;
  font-size: 16px;
  border-radius: 5px;
  background-color: #f88e24;
  border: none;
  cursor: pointer;
}

#btn:hover {
  opacity: .9;
}

.row_footer {
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin: 0 auto 8px;
  padding: 0 3%;
  font-size: 15px;
  color: #999;
  cursor: pointer;
}

.row_footer > div {
  padding: 10px;
}

.row_footer > .active {
  color: #f88e24;
}
