.dialog {
  background-color: rgba(255, 255, 255, .2);
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 10;
}

.dialog > .box {
  width: 400px;
  min-height: 200px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  border-radius: 10px;
  overflow: hidden;
}

.dialog > .box > .title {
  width: 100%;
  height: 50px;
  line-height: 50px;
  text-align: center;
  color: #fff;
  background-color: #f88e24;
}

.box > .dialog_close {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 22px;
  height: 22px;
  font-size: 15px;
  color: #fff;
  cursor: pointer;
  border-radius: 50%;
  border: 1px solid #fff;
  position: fixed;
  top: 12px;
  right: 15px;
}

.dialog > .box > .content {
  text-align: center;
  padding: 10px;
}

.wechat {
  cursor: pointer;
  margin: 10px 30px;
}

.qr_dialog > .box {
  width: 80%;
}

.prompt {
  margin: 7px 0;
  font-size: 13px;
}

.small {
  font-size: 13px;
}

.qrcode {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 15px;
}

.qrcode > img {
  width: 100px;
}

.alipay {
  cursor: pointer;
  margin: 10px 30px;
}

.pay_function {
  display: flex;
  justify-content: center;
}

.pay_function > a {
  color: #000;
  text-decoration: none;
  position: relative;
  z-index: 999;
}

.pay_function_item {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  margin: 0 30px;
}

.pay_img {
  height: 40px;
  margin-bottom: 10px;
}