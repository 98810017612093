.body {
  width: 100%;
  height: 100%;
  min-height: 100vh;
  background-color: #999;
}

.box {
  width: 400px;
  min-height: 100px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  border-radius: 15px;
  box-sizing: border-box;
  overflow: hidden;
}

.go_back {
  font-size: 24px;
  cursor: pointer;
  position: absolute;
  right: 15px;
  top: 3px;
}

.title {
  width: 100%;
  height: 50px;
  line-height: 50px;
  text-align: center;
  color: #fff;
  background-color: #f88e24;
  position: relative;
}

.nav {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  height: 40px;
  font-size: 15px;
  margin-bottom: 5px;
  color: #999;
  background-color: #f4f4f4;
  position: relative;
}

.nav_item {
  display: flex;
  align-items: center;
}

.icon_arrow {
  display: block;
  content: '';
  width: 8px;
  height: 8px;
  margin-left: 5px;
  border: 3px solid #ccc;
  border-width: 2px 2px 0 0;
  transform: rotate(45deg);
}

.nav .nav_item.active {
  color: #f88e24 !important;
}

.nav .nav_item.active .icon_arrow {
  display: block;
  content: '';
  width: 8px;
  height: 8px;
  margin-left: 5px;
  border: 3px solid #f88e24;
  border-width: 2px 2px 0 0;
  transform: rotate(45deg);
}

.content {
  padding: 0 30px 5px;
}

.row {
  width: 100%;
  margin: 10px 0;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid #ececec;
}

.phone_verfity {
  width: 100%;
  text-align: center;
  font-size: 12px;
  color: #999;
  margin-top: 5px;
}

.phone_row {
  margin: 8px 0;
}

.icon {
  width: 35px;
  height: 35px;
  cursor: pointer;
}

.phone_close {
  background: #f88e24;
  color: #fff;
  border-radius: 50%;
  width: 26px;
  height: 23px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.input {
  width: 100%;
  height: 40px;
  border: none;
  outline: none;
}

.time {
  font-size: 12px;
  color: red;
  white-space: nowrap;
}

.send_verfity {
  white-space: nowrap;
  margin-left: 5px;
  cursor: pointer;
  color: #999;
  font-size: 14px;
}

.btn {
  width: 100%;
  height: 40px;
  color: #fff;
  font-size: 16px;
  border-radius: 5px;
  background-color: #f88e24;
  border: none;
  cursor: pointer;
}

.btn:hover {
  opacity: .9;
}

.no_verfity {
  white-space: nowrap;
  font-size: 10px;
  color: #999;
  text-align: center;
  margin: 8px 0;
}